<template>
  <div class="sidebar" v-loading="loading">
    <el-menu
      :unique-opened="true"
      default-active="0-0"
      class="el-menu-vertical-demo"
    >
      <div v-for="(list, index) in sidebarData" :key="list.id">
        <el-menu-item v-if="!list.children.length" :index="`${index}`">
          <span slot="title" @click="handleChangePlot(list)">{{
            list.label
          }}</span>
        </el-menu-item>
        <el-submenu :index="`${index}`" v-else>
          <template slot="title">
            <span class="itemS_title">{{ list.label }}</span>
          </template>
          <el-menu-item
            v-for="(item, i) in list.children"
            :key="item.id"
            :index="`${index}-${i}`"
            @click="handleChangePlot(item)"
          >
            {{ item.label }}
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    sidebarData: Array,
    handleChangePlot: Function,
  },
  name: "Sidebar",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/.el-menu {
  border: none !important;
  .el-submenu__title {
    .itemS_title {
      display: -webkit-box !important;
      width: 250px !important;
      white-space: break-spaces !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
    }
  }
}
.sidebar {
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}
</style>
